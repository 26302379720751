@import 'theme/variable';

$primary: #fd6d64;
$secondary: #434345;
$dark: #FC466B;
$light: #3F5EFB;
@import 'theme/style';
@import 'theme/responsive';
@import 'theme/customize';
@import 'theme/theme-dark';
@import 'theme/theme-rtl';